import React, { useEffect, useRef, useState} from 'react';
//import components
import DefaultHeader from './components/DefaultHeader';
import Home from './pages/Home';
import About from './pages/About';
import Shorts from './pages/Shorts';
import Contact from './pages/Contact';
import Work from './pages/Work';
// import AnimRoutes from './components/AnimRoutes';

const App = () => {
  const sectionRef = useRef(null);

  const handleSectionRef = (ref) => {
    sectionRef.current = ref;
  };
  const [isHeaderChange, setIsHeaderChange] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
       setIsHeaderChange(window.pageYOffset > sectionRef.current.clientHeight)
      );
    }
  }, []);
  
  return (
  // <div className=''>
    <>
    <DefaultHeader isHeaderChange={isHeaderChange} />
    <Home onSectionRef={handleSectionRef}/>
    <Shorts />
    <Work/>
    <About/>
    <Contact/>
      {/* <Router>
        <AnimRoutes />
      </Router> */}
      </>
    // </div>
  )
};

export default App;
