import React from 'react';
import { FaVimeo , FaInstagram , FaLinkedin  } from 'react-icons/fa';

const Socials = () => {
  return <>
   {/* Socials */}
    <a href='https://www.instagram.com/gautammonangi.films/' target='_blank' rel="noreferrer">
      <FaInstagram/>
    </a>
    <a href='https://www.linkedin.com/in/gautam-monangi-bb481a206?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' target='_blank' rel="noreferrer">
      <FaLinkedin />
    </a>
    <a href='https://vimeo.com/gautammonangi' target='_blank' rel="noreferrer">
      <FaVimeo />
    </a>
  {/* </motion.div> */}
  </>
};

export default Socials;
