import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'
import shecommercePoster from '../media/poster/shecommerce.webp';
import Asaya from '../media/poster/Asaya.webp';
import NagwaPoster from '../media/poster/Nagwa.webp';
import ReveriePoster from '../media/poster/Reverie.webp';
import suavePoster from '../media/poster/suave.webp';
// import { homeMedia } from '../components/MediaPaths';

// const VideoRender = React.lazy(()=> import('../components/VideoRender'));


const Home = ({onSectionRef}) => {
  const homeSection = useRef(null);
  const [videoLinks] = useState([
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoAsaya,
      //   webm: homeMedia.bgVideoAsayaWebm,
      // },
      title: 'Asaya - Welcome Home',
      redirectLink: 'https://vimeo.com/856639345',
      fileName: 'Asaya.mp4',
      poster: Asaya
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoReverie,
      //   webm: homeMedia.bgVideoReverieWebm
      // },
      title: 'REVERIE',
      redirectLink: 'https://vimeo.com/730222592',
      fileName: 'Reverie.mp4',
      poster: ReveriePoster
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoShecommerz,
      //   webm: homeMedia.bgVideoShecommerzWebm,
      // },
      title: 'Shecommerz',
      redirectLink: 'https://vimeo.com/938484704',
      fileName: 'Shecommerz.mp4',
      poster: shecommercePoster
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoNagwa,
      //   webm: homeMedia.bgVideoNagwaWebm,
      // },
      title: 'Nagwa x Hopo',
      redirectLink: 'https://vimeo.com/857532688',
      fileName: 'Nagwa.mp4',
      poster: NagwaPoster
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoSuaveMundane,
      //   webm: homeMedia.bgVideoSuaveMundaneWebm,
      // },
      title: 'Suave Mundane',
      redirectLink: 'https://vimeo.com/857528166',
      fileName: 'suave.mp4',
      poster: suavePoster
    }
  ])

  const [bgVideoIndex, setBgVideoIndex] = useState(0);
  const playerRefs = useRef(videoLinks.map(() => React.createRef()));
  // const [touchStart, setTouchStart] = useState(null)
  // const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  // const minSwipeDistance = 50 

  // const onTouchStart = (e) => {
  //   setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
  //   setTouchStart(e.targetTouches[0].clientX)
  // }

  // const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  // const onTouchEnd = () => {
  //   if (!touchStart || !touchEnd) return
  //   const distance = touchStart - touchEnd
  //   const isRightSwipe = distance < -minSwipeDistance
  //   alert(distance)
  //   if (isRightSwipe) {
  //     setBgVideoIndex(bgVideoIndex === 4? 0 : bgVideoIndex+1);
  //   }
  // }

  async function setBgVideoIndexStart(index){
    
    if (playerRefs.current[index].current) {
      index === 4 ? playerRefs.current[index].current.seekTo(11, 'seconds') : playerRefs.current[index].current.seekTo(0, 'seconds');
    }
    await sleep(200);
    setBgVideoIndex(index);
  }

  useEffect(() => {
    if (onSectionRef && typeof onSectionRef === 'function') {
      onSectionRef(homeSection.current);
    }
  }, [onSectionRef]);

  // function handleError(index) {
  //   playerRefs.current[index].current.seekTo(0, 'seconds');
  // }

  function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  return (
   <section ref={homeSection} className='section bg-cover h-auto' id='home'>
    <div className='landing'
    // onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
    >
      <div className='h-[full] relative'
       >
        {
          videoLinks.map((videoLink, index) => (
              <div className={`${bgVideoIndex === index ? "" : "video-bg"} ${'player-wrapper'}`} >
                  <ReactPlayer
                  ref={playerRefs.current[index]}
                  className='react-player'
                  playing muted loop playsinline
                  url={videoLink.redirectLink}
                  width='100%' height='100%'
                  config={{
                      playerOptions: {
                          playsinline: true
                  }
                  }}
                  // onError={() => handleError(index)}
                  >
                  </ReactPlayer>
              </div>
          ))
        }
        {/* <VideoRender data={videoLinks} videoIndex={bgVideoIndex} playerRef={playerRefs}/> */}
        <div className='video-nav-mobile xl:hidden'>
          {
            videoLinks.map((videoLink, index) => (
              <div key={index} onClick={() => setBgVideoIndexStart(index)} className='navbar-collection-item'>
                <div className='navlink_button w-inline-block'>
                  <div className={`${bgVideoIndex === index ? "text-white" : ""} ${'bulletpoint'}`}>•</div>
                </div>
              </div>
            ))
          }
        </div>
        <div className='div-block-starting-page w-container'>
          {
            videoLinks.map((videoLink, index) => (
              <a key={index} onMouseEnter={() => setBgVideoIndexStart(index)} className='link-block w-inline-block' href={videoLink.redirectLink} target='_blank' rel="noreferrer">
                <div className='text-starting font-normal hover:italic'>
                  {videoLink.title}
                </div>
              </a>
            ))
          }
        </div>
        </div>
      
    </div>
      
    </section>
  );
};

export default Home;
