import React, { useState, lazy,  Suspense}from 'react';

import { fadeIn } from '../variants';
import { collaboratorImages } from '../components/MediaPaths';

// import { Link } from 'react-router-dom';
// import  motion
import { motion } from 'framer-motion';
import Community from '../components/Community';
const Clients = lazy(() => import('../components/Clients'));
//  from '../components/Clients';


const About = () => {
  // const aboutSection = useRef(null);
  const [collaborators] = useState([
    {
      rowID: '1',
      rowData: [
        {
          name: 'Gautam Monangi',
          role: 'Director',
          link: '',
          image: collaboratorImages.g
        },
        {
          name: 'Prithvi Raj',
          role: 'Producer',
          link: 'https://prithvi4960.notion.site/Prithvi-Raj-f2255719cdc54418bf5184165016a30d?pvs=4',
          image: collaboratorImages.Prithvi_Raj
        },
      ]
    },
    {
      rowID: '2',
      rowData: [
        {
          name: 'Tanmay Biyani',
          role: 'Producer. Founder of Clink Films.',
          link: 'http://www.clinkfilms.com',
          image: collaboratorImages.Tanmay_Biyani,
          style: 'w-[114px]'
        },
        {
          name: 'Aravind Yuvaraj',
          role: 'DOP',
          link: 'https://www.instagram.com/createdby.aravindhyuvaraj?igsh=NWtiZHI1Y3gwYWJm',
          image: collaboratorImages.Aravind_DOP
        },
      ]
    },
    {
      rowID: '3',
      rowData: [
        {
          name: 'Aswin Sumesh',
          role: 'DOP',
          link: 'http://linktr.ee/aswinsumesh',
          image: collaboratorImages.Aswin
        },
        {
          name: 'Tiara Turner',
          role: 'Writer',
          link: 'https://www.linkedin.com/in/tiara-turner-037242207/',
          image: collaboratorImages.Tiara
        },
      ]
    },
    {
      rowID: '4',
      rowData: [
        {
          name: 'Dhruv Kashyap',
          role: 'Business developer, Designer.',
          link: 'https://www.behance.net/dhruvkashyap',
          image: collaboratorImages.Dhruv_Kashyap,
          style: 'w-[100px]'
        }
      ]
    },
  ]);
  // const [isVisible, setIsVisible] = useState(false);
  
  //   useEffect(() => {
  //     const observer = new IntersectionObserver((entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           setIsVisible(true);
  //           observer.unobserve(entry.target);
  //         }
  //       });
  //     },{
  //       threshold: 1
  //     });
  
  //     observer.observe(aboutSection.current);
  
  //     // Cleanup function
  //     return () => {
  //       observer.disconnect();
  //     };
  //   }, [aboutSection]);

  return <section className='section bg-cover h-auto' id={'about'} >
    <div className='container mx-auto'>
      <div className='flex flex-col gap-x-24
       text-center lg:text-left'>
        <div className='flex-1 pt-36 px-4 lg:w-auto z-10 flex flex-col items-center text-center'>{
          <>
            <motion.h1
            variants={fadeIn('up', 0.3)} initial="hidden"
            whileInView={'show'} viewport={{once: true, amount: 0.5}}
            className='h1'>About Gautam Monangi Films</motion.h1>
            <motion.div 
            variants={fadeIn('right', 0.3)} initial="hidden"
            whileInView={'show'} viewport={{once: true, amount: 0.5}}
            >
              <p className='mb-12 max-w-[50rem]'>
              Somewhere between trying to “sell” products and services, we often end up selling the story as well. Why is this far less profitable than it sounds? because if we look back into how human beings have survived, learnt, built ‘communities’ and evolved….it all comes down to one thing - well-told stories sell themselves.
              <br />
              <br />
              At Gautam Monangi Films, we believe in ‘show not sell’. In the right hands, commercial films could be just as memorable… just as artistic as cinema. So here we are, arms and lens wide open, ready to depict your story as aired before only in your vision
              <br />
              <br />
              GMF is your own personal community of creators, carefully brought together, waiting to create art out of that story - a souvenired invitation for your audience to keep and revisit, like a scene from their favourite movie, in the everyday moments of life.
              </p>
            </motion.div>
            
            <div className='lg:flex-1 mb-8'>
                <Community collaborators={collaborators} />
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <Clients/>
            </Suspense>
          </>
        }
          
        </div>
      </div>
    </div>
  </section>;
};

export default About;
