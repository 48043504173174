import React, { useState, useRef , lazy } from 'react';

import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

// import logos
import vegNonVegLogo from '../img/logos/VegNonVeg.png';
import TO6Logo from '../img/logos/Tribeof6.webp'
import demeLogo from '../img/logos/Deme.png'
import ModishLogo from '../img/logos/Modish.png'
import MokobaraLogo from '../img/logos/Mokobara.jpg'
import AsayaLogo from '../img/logos/Asaya.png'
import SimbaLogo from '../img/logos/Simba.png'
import SaykLogo from '../img/logos/Spy Hard Seltzer.png'
import keiflogo from '../img/logos/keif.webp'
import Nail_StationLogo from '../img/logos/Nail station.png'
import SculpLogo from '../img/logos/Sculp tattoos.png'
import DhahiaLogo from '../img/logos/Dhahia juice.png'
import Nothing from '../img/logos/Nothing.png';
import AcquaLogo from '../img/logos/Acqua.png';
import AtyabLogo from '../img/logos/Mello.png';
import ArnavLogo from '../img/logos/Arnav.PNG';

import { paths } from '../components/MediaPaths';
const ShortVideos = lazy(()=> import('../components/ShortVideos'));
// import process 

const Shorts = () => {
  const shortsSection = useRef(null);
  const [reels] = useState([
    {
      // data: {
      //   mp4: paths.vegnonveg,
      //   webm: paths.vegnonveg_webm
      // },
      poster: paths.vegnonveg_poster,
      vimeoLink: 'https://vimeo.com/857542836',
      redirectLink: 'https://www.instagram.com/reel/CvATxjUBbiI/?igsh=OWtyaWluZnBmeHdu',
      title: 'vegnonveg',
      thumbnail: '',
      logo: vegNonVegLogo,
      class: 'down _01',
      dimension: 'w-[37%] h-[19%]'
    },
    {
      // data: {
      //   mp4: paths.bgTribe_of_6,
      //   webm: paths.bgTribe_of_6_Webm,
      // },
      poster: paths.bgTribe_of_6_poster,
      vimeoLink: 'https://vimeo.com/857565863',
      redirectLink: 'https://www.instagram.com/reel/Cv91CRDh73p/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
      title: 'tribe Of 6',
      thumbnail: '',
      logo: TO6Logo,
      class: 'down',
      dimension: 'w-[84%]'
    },
    {
      // data: {
      //   mp4: paths.deme,
      //   webm: paths.deme_webm,
      // },
      poster: paths.deme_poster,
      vimeoLink: 'https://vimeo.com/890593414',
      redirectLink: 'https://www.instagram.com/reel/C0TV9aMhnyU/?igsh=MWwxNnJoMHl5ZTllNg==',
      title: 'Deme',
      thumbnail: '',
      logo: demeLogo,
      class: 'down',
      dimension: 'w-[84%]'
    },
    {
      // data: {
      //   mp4: paths.FormOfStyle,
      //   webm: paths.FormOfStyle_webm
      // },
      poster: paths.FormOfStyle_poster,
      vimeoLink: 'https://vimeo.com/890606836',
      redirectLink: 'https://www.instagram.com/reel/C0eD2FjhxHq/?igsh=c2xzbXVrcmRja2dn',
      title: 'FormOfStyle',
      thumbnail: '',
      logo: ModishLogo,
      class: 'down',
      dimension: 'w-[50%] h-[22%]'
    },
    {
      // data: {
      //   mp4: paths.Mokodora,
      //   webm: paths.Mokodora_webm
      // },
      poster: paths.Mokodora_poster,
      vimeoLink: 'https://vimeo.com/857590834',
      redirectLink: 'https://www.instagram.com/reel/CwKseqIJLME/?igsh=enUwZ2IwbWxzOWx4',
      title: 'Mokobora',
      thumbnail: '',
      logo: MokobaraLogo,
      class: 'down _01',
      dimension: 'w-[55%]'
    },
    {
      // data: {
      //   mp4: paths.NishaXAsaya,
      //   webm: paths.NishaXAsaya_webm
      // },
      poster: paths.NishaXAsaya_poster,
      vimeoLink: 'https://vimeo.com/900518391',
      redirectLink: 'https://www.instagram.com/reel/C3AkuFku4L0/?igsh=MWVpaTV5YmFpbWlrbA==',
      title: 'NishaXAsaya',
      thumbnail: '',
      logo: AsayaLogo,
      class: 'up',
      dimension: 'w-[250%]'
    },
    {
      // data: {
      //   mp4: paths.Simba,
      //   webm: paths.Simba_webm
      // },
      poster: paths.Simba_poster,
      vimeoLink: 'https://vimeo.com/938800014',
      redirectLink: 'https://www.instagram.com/reel/C3AkuFku4L0/?igsh=MWVpaTV5YmFpbWlrbA==',
      title: 'Simba',
      thumbnail: '',
      logo: SimbaLogo,
      class: 'down',
      dimension: 'w-[55%] h-[12%]'
    },
    {
      // data: {
      //   mp4: paths.SpyK,
      //   webm: paths.SpyK_webm
      // },
      poster: paths.SpyK_poster,
      vimeoLink: 'https://vimeo.com/938798471',
      redirectLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
      title: 'SpyK',
      thumbnail: '',
      logo: SaykLogo,
      class: 'up',
      dimension: 'w-[48%] h-[11%]'
    },
    {
      // data: {
      //   mp4: paths.Keif,
      //   webm: paths.Keif_webm
      // },
      poster: paths.Keif_poster,
      vimeoLink: 'https://vimeo.com/901579158',
      redirectLink: 'https://www.instagram.com/reel/CfYiUgUB5LI/?igsh=MXV0Mzc5YTZ0Y216cQ==',
      title: 'Keif',
      thumbnail: '',
      logo: keiflogo,
      class: 'down _01',
      dimension: 'w-[55%]'
    },
    {
      // data: {
      //   mp4: paths.Nail_Station,
      //   webm: paths.Nail_Station_webm
      // },
      poster: paths.Nail_Station_poster,
      vimeoLink: 'https://vimeo.com/938792138',
      redirectLink: 'https://www.instagram.com/tv/CZcBqVshNy5/?igsh=MnRyaTJwdG9jcm9i',
      title: 'Nail_Station',
      thumbnail: '',
      logo: Nail_StationLogo,
      class: 'up',
      dimension: 'w-[61%] h-[11%]'
    },
    {
      // data: {
      //   mp4: paths.Bhavani,
      //   webm: paths.Bhavani_webm
      // },
      poster: paths.Bhavani_poster,
      vimeoLink: 'https://vimeo.com/938799808',
      redirectLink: 'https://www.instagram.com/reel/Cm6j0Ymq80x/?igsh=Y3hxdnpxM3NrMno3',
      title: 'Bhavani',
      thumbnail: '',
      logo: SculpLogo,
      class: 'down',
      dimension: 'w-[50%] h-[28%]'
    },
    {
      // data: {
      //   mp4: paths.Dhahia,
      //   webm: paths.Dhahia_webm
      // },
      poster: paths.Dhahia_poster,
      vimeoLink: 'https://vimeo.com/939143933',
      redirectLink: 'https://www.instagram.com/tv/CaE82LQNFmD/?igsh=azM1bGF2bzBjaTE1',
      title: 'Dhahia',
      thumbnail: '',
      logo: DhahiaLogo,
      class: 'up',
      dimension: 'w-[90%] h-[15%]'
    },
    {
      // data: {
      //   mp4: paths.nothing,
      //   webm: paths.nothing_webm
      // },
      poster: paths.nothing_poster,
      vimeoLink: 'https://vimeo.com/938799865',
      redirectLink: 'https://vimeo.com/938799865',
      title: 'Nothing',
      thumbnail: '',
      logo: Nothing,
      class: 'down _01',
      dimension: 'w-[55%] h-[10%]'
    },
    {
      // data: {
      //   mp4: paths.Arnav_Personal,
      //   webm: paths.Arnav_Personal_webm,
      // },
      poster: paths.Arnav_Personal_poster,
      vimeoLink: 'https://vimeo.com/938801042',
      redirectLink: 'https://vimeo.com/938801042',
      title: 'Arnav_Personal',
      thumbnail: '',
      logo: ArnavLogo,
      class: 'down _01',
      dimension: 'w-[50%]'
    },
    {
      // data: {
      //   mp4: paths.Acqua,
      //   webm: paths.Acqua_webm
      // },
      poster: paths.Acqua_poster,
      vimeoLink: 'https://vimeo.com/938977831',
      redirectLink: 'https://vimeo.com/938977831',
      title: 'Acqua',
      thumbnail: '',
      logo: AcquaLogo,
      class: 'down _01',
      dimension: 'w-[37%] h-[19%]'
    },
    {
      // data: {
      //   mp4: paths.Mello,
      //   webm: paths.Mello_webm,
      // },
      poster: paths.Mello_poster,
      vimeoLink: 'https://vimeo.com/938793505',
      redirectLink: 'https://vimeo.com/938793505',
      title: 'Mello',
      thumbnail: '',
      logo: AtyabLogo,
      class: 'down _01',
      dimension: 'w-[65%]'
    },
  ])

  return (
    <section className='section bg-cover h-auto' id={'shorts'} >
      <div className='container mx-auto'>
        <div className='flex pt-36 flex-col items-center gap-x-24 text-center lg:text-left'>
            <motion.div 
            variants={fadeIn('up', 0.3)} initial="hidden"
            whileInView={'show'} viewport={{once: true, amount: 0.5}}
            className='text-block-commercial'>
              Featured Short-Form
            </motion.div>
            <div ref={shortsSection} className='scroll-container-v2'>
            <ShortVideos reels={reels} />
            </div>
        
        </div>
        </div>
    </section>
  );
};

export default Shorts;
